import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import * as styles from './ProductCollectionGrid.module.css';

import ProductCollection from '../ProductCollection';

const ProductCollectionGrid = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allGoogleHomepagecollectionSheet {
        edges {
          node {
            title
            image
          }
        }
      }
    }
  `);
  const collections = data?.allGoogleHomepagecollectionSheet?.edges;
  return (
    <div className={styles.root}>
      {collections?.map((collection) => {
        return (
          <ProductCollection
            image={collection?.node?.image}
            title={collection?.node?.title}
            text={'SHOP NOW'}
            link={`/shop?category=${collection?.node?.title}`}
          />
        );
      })}
    </div>
  );
};

export default ProductCollectionGrid;
