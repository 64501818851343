import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import AttributeGrid from '../components/AttributeGrid';
import Container from '../components/Container';
import Layout from '../components/Layout/Layout';
import ProductCollectionGrid from '../components/ProductCollectionGrid';
import ProductCardGrid from '../components/ProductCardGrid';
import Title from '../components/Title';

import * as styles from './index.module.css';
import SitePreview from '../components/SitePreview/SitePreview';
import { useAppContext } from '../context/useAppContext';

const IndexPage = () => {
  const { productsData, fetchData } = useAppContext();

  React.useEffect(() => {
    fetchData();
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allGoogleGlobalSheet {
        edges {
          node {
            heroImage
            heroSubTitle
            heroTitle
            homeCopy
          }
        }
      }
      allGoogleProductsSheet {
        edges {
          node {
            slug
            title
            price
            images
            sku
            status
            weight
          }
        }
      }
    }
  `);

  const newArrivals = productsData
    ?.filter((product) => product.status === 'active')
    ?.slice(0, 3);

  // alt,
  // image,
  // name,
  // price,

  return (
    <Layout disablePaddingBottom>
      {/* Hero Container */}
      {/* <Hero
        maxWidth={'800px'}
        image={pageData?.heroImage}
        title={pageData?.heroTitle}
        subtitle={pageData?.heroSubTitle}
        ctaText={'shop now'}
        ctaAction={goToShop}
      /> */}

      {/* Message Container */}
      {/* <div className={styles.messageContainer}>
        <p dangerouslySetInnerHTML={{ __html: pageData?.homeCopy }} />
      </div> */}

      <div
        style={{
          marginBottom: '32px',
        }}
      />

      <SitePreview />

      {/* Collection Container */}
      <div className={styles.collectionContainer}>
        <Container size={'large'}>
          <Title name={'Browse Our Collection'} />
          <ProductCollectionGrid />
        </Container>
      </div>

      {/* New Arrivals */}
      <div className={styles.newArrivalsContainer}>
        <Container>
          <Title name={'New Arrivals'} link={'/shop'} textLink={'view all'} />
          <ProductCardGrid
            spacing={true}
            showSlider
            height={480}
            columns={3}
            data={newArrivals}
          />
        </Container>
      </div>

      {/* Highlight  */}
      {/* <div className={styles.highlightContainer}>
        <Container size={'large'} fullMobile>
          <Highlight
            image={'/highlight.png'}
            altImage={'highlight image'}
            miniImage={'/highlightmin.png'}
            miniImageAlt={'mini highlight image'}
            title={'Luxury Knitwear'}
            description={`This soft lambswool jumper is knitted in Scotland, using yarn from one of the world's oldest spinners based in Fife`}
            textLink={'shop now'}
            link={'/shop'}
          />
        </Container>
      </div> */}

      {/* Promotion */}
      {/* <div className={styles.promotionContainer}>
        <Hero image={'/banner2.png'} title={`-50% off \n All Essentials`} />
        <div className={styles.linkContainers}>
          <Link to={'/shop'}>WOMAN</Link>
          <Link to={'/shop'}>MAN</Link>
        </div>
      </div> */}

      <div
        style={{
          marginBottom: '48px',
        }}
      />

      {/* Quote */}
      {/* <Quote
        bgColor={'var(--standard-light-grey)'}
        title={'about Marion'}
        quote={
          '“We believe in two things: the pursuit of quality in everything we do, and looking after one another. Everything else should take care of itself.”'
        }
      /> */}

      {/* Blog Grid */}
      {/* <div className={styles.blogsContainer}>
        <Container size={'large'}>
          <Title name={'Journal'} subtitle={'Notes on life and style'} />
          <BlogPreviewGrid data={blogData} />
        </Container>
      </div> */}

      {/* Promotion */}
      {/* <div className={styles.sustainableContainer}>
        <Hero
          image={'/banner3.png'}
          title={'We are Sustainable'}
          subtitle={
            'From caring for our land to supporting our people, discover the steps we’re taking to do more for the world around us.'
          }
          ctaText={'read more'}
          maxWidth={'660px'}
          ctaStyle={styles.ctaCustomButton}
        />
      </div> */}

      {/* Social Media */}
      {/* <div className={styles.socialContainer}>
        <Title
          name={'Styled by You'}
          subtitle={'Tag @sydney to be featured.'}
        />
        <div className={styles.socialContentGrid}>
          <img src={`/social/socialMedia1.png`} alt={'social media 1'} />
          <img src={`/social/socialMedia2.png`} alt={'social media 2'} />
          <img src={`/social/socialMedia3.png`} alt={'social media 3'} />
          <img src={`/social/socialMedia4.png`} alt={'social media 4'} />
        </div>
      </div> */}
      {/* <AttributeGrid /> */}
    </Layout>
  );
};

export default IndexPage;
