import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import * as styles from './SitePreview.module.css';
import Container from '../Container';
import { Spacer } from '../../uiBook';

const SitePreview = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allGoogleSitepreviewSheet {
        edges {
          node {
            imageAlt
            imageUrl
            title
            copy
          }
        }
      }
    }
  `);
  const pageData = data?.allGoogleSitepreviewSheet?.edges[0]?.node;
  return (
    <div className={styles.root}>
      <Container size={'large'}>
        <h2>{pageData?.title}</h2>
        <p>{pageData?.copy}</p>
      </Container>
    </div>
  );
};

export default SitePreview;
